
export default {
  name: 'AtomInfoPrice',
  props: {
    icon: {
      required: false,
      type: String,
      default: 'info'
    }
  },
  computed: {},
  methods: {}
}
